 /** the ~ allows you to reference things in node_modules  */
 
@import "abstracts/variables";
@import "abstracts/variables_bayme_vbm.scss";

@import "abstracts/bootstrap_variables_vbw.scss";
@import "abstracts/bootstrap_variables_bayme_vbm.scss";

@import "abstracts/mixins.scss";

@import "util/responsive.scss";

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "../lib/confirm/jquery-confirm.min.css";
@import "~magnific-popup/src/css/main.scss";
@import "~jquery-autocomplete/jquery.autocomplete.css";
@import "~jquery-fileinput/fileinput.css";
@import "~flickity/css/flickity.css";

@import "base/fonts.scss";
@import "base/typography.scss";
@import "base/base.scss";

@import "base/helpers.scss";

@import "components/forms.scss";
@import "components/icons.scss";
@import "components/box_bayme_vbm.scss";
@import "components/accordion.scss";
@import "components/toolbar.scss";
@import "components/close.scss";
@import "components/close.scss";

@import "plugins/selectize.scss";

@import "layout/header_bayme_vbm.scss";
@import "layout/footer_bayme_vbm.scss";


@import "pages/article.scss";
@import "pages/event_registration.scss";
@import "pages/publication_order.scss";

@import "themes/_bayme_vbm.scss";

@import "util/print.scss";
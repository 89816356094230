// -----------------------------------------------------------------------------
// This file contains styles that are specific to the event registration page.
// -----------------------------------------------------------------------------

#event_registration_container {

    .controls-container {
    
        @extend .d-flex, .justify-content-between, .align-items-center, .mb-0, .mt-3;

    
    }
    
    .event-participation-type-notice {
        
        @extend .alert !optional;
        @extend .alert-primary !optional;
        @extend .bg-yellow !optional;
        @extend .d-inline-block !optional;
    
    } 
    
    .meta {
    
        .event-info {
        
            @extend .col-xl-9, .col-lg-8, .col-md-7, .bg-grey, .py-4, .px-4;
            
            .title-container {
            
                @extend .mb-3;
            
            }
                    
        }
        
        .event-contact-person {
        
            @extend .col-xl-3, .col-lg-4, .col-md-5;
        
        }
    
    }
        
    #steps {
        
        @extend .col-12, .px-4, .py-4, .bg-grey, .border-bottom !optional;
    
    }
    
    #messages {
        
        @extend .col-12;
    
    }
    
    .error {
    
        input {
            border: 1px solid $danger !important;
        }
    
        .error-msg {
            color: $danger;
        }
    
    }
    
    .registration-inner {
    

        @extend .col-12, .px-4, .py-4, .bg-grey;
    
    }
    
    #event_registration_details {
    
        .attendee:not(:last-child) {
        
            @extend .mb-4;
        
        }
        
    }

    #event_registration_topics {
    
    }
    
    #event_registration_confirm {
    
        .attendee:not(:last-child) {
        
            @extend .mb-4;
        
        }
    
    }
    
    #event_registration_success {
    
    }
    
    .booked-out-notice {
        color: initial !important;
    }
    
    ul {
    
        @extend .list-unstyled, .black, .mb-0, .px-0;
        
        li {
        
            @extend .mx-0, .mb-4, .align-items-center;
            
            label {
            
                @extend .d-table;
                
                input {
                
                    @extend .mr-3;
                
                }
                
                > span {
                
                    @extend .d-table-cell;
                
                }
            
            }
            
            &.error {
            
                label {
                
                    color: $danger;
                
                }
            
            }
        
        }
    
    }

}

body.baymevbm {

    #event_registration_container {
    
        *, *::before, *::after {
            box-sizing: border-box;
        }
    
        line-height: 1.2rem;
        font-size: 13px;
    
    
        .generic-form {
        
            .form-control {
                font-size: 0.9em !important;
            }
        
            .selectize-dropdown-content {
                font-size: 1.0em !important;
            }
        
            .selectize-input {
            
                padding: 6px;
                font-size: 1em !important;
            
                &.not-full {
                    min-height: 32px;
                }
            
            }
            
        }
    
        button {
            background-image: initial;
            background-color: map-get($colors, 'blue');
        }
    
        
        label {
                        
            font-size: 0.9em !important;
            margin-bottom: 5px;
        
            &.form-check-label {
                margin-bottom : 0;
            }
        
        }
        
        .gender-container {
            @extend .pt-2;
        }
        
        #steps {
                        
            h3 {
            
                @extend .h5;
                
            }
            
            h1, h2, h3, h4, h5, h6 {
                font-family: 'Neue Haas Unica W01 Regular', sans-serif;
            }
            
            .strong {
                font-family: 'Neue Haas Unica W01 Medium It', sans-serif;
            }
        
        }
        
        .error {
            padding: initial;
            color: initial;
            background: initial;
            border: initial;
            margin: initial;
        }
        
        #event_meta {
        
            #event_meta_row {
            
                #event_meta_col_left {
                
                    @extend .col-12, .col-md-8, .pl-0;
                    
                    #event_meta_title, .font-size-20, .font-size-md-40 {
                        font-family: 'GeogrotesqueW01-Regular1185161';
                        color: #4f95c9; 
                    }
                    
                }
                
                #event_meta_col_right {
                    
                    .font-size-inherit {
                        font-size: inherit !important;
                    }
                    
                    @extend .col-12, .col-md-4, .px-0;
                }
            
            }
        
            font-size: 0.9rem;
            
            figure {
                margin: 0 0 1rem;
            }
            
            .icon {
                        
                position: initial;
                left: initial;
                padding: initial;
                background-repeat: initial;
                background-position: initial;
            
            }
            
            .email {
            
                background-image: initial;
            
            }
            
            .text11 {
                
                font-size: 0.9rem;
            
            }
            
            .microline-light {
            
                color: #2b323c !important; 
            
            }
            
            .font-size-md-40 {
            
                font-size: 28px !important;
            
            }
            
            .font-size-25 {
            
                font-size: 20px !important;
            
            }
            
            @include respond-below(md) {
            
                margin-left: -20px;
                margin-right: -20px;
            
            }
        
        }
        
        #event_registration_inner_container {
        
            @media screen and (min-width: 671px) and (max-width: 767px) {
            
                p {
                
                    font-size: 1.2rem !important;
                
                }
            
            }
        
            @include respond-below(md) {
            
                margin-left: -20px;
                margin-right: -20px;
            
                p {
                    font-size: 1em;
                }
                
                label {
                
                    
                
                }
                
                input[type=radio] + label::before, input[type=checkbox] + label::before, input[type=checkbox] + input[type=hidden] + label::before {
                
                }
                
                
                input, select {
                
                    font-size: 0.9em !important;
                
                }
            
            }
            
            @include respond-below(sm) {
                
            }
        
            #event_registration_details {
        
                background-color: #f2f4f6;

            }
            
            .event-participation-type-notice {
        
                @extend .pad !optional;
                @extend .bg-yellow !optional;
                @extend .inline-block !optional;
                @extend .margT !optional;
            
            } 
        
        }
        
        
        
        @media screen and (max-width: 1000px) {
        
            
        
        }
    
    }

}

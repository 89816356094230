@mixin print {
  @media print {
    @content;
        
	
  }
}

@mixin not-print {
  @media not print {
    @content;
  }
}

@media print {

    *,
    .box-headline--white,
    .microline-white {
        background: transparent !important;
        color: #000 !important;
        text-decoration: none !important;
    }

    .article-content-text-cols {
        width: 60%;
    }
    
    .article-content-right {
        float: right;
    }
    
    .top-theme-image {
    	width: 200px !important;
    }
    
    .about-us-section img,
    .events-section img,
    .in-focus-section img {
    	visibility: hidden;
    	max-height: 200px;
    }
    
    .swiper-wrapper {
    	display: block !important;
    }
    
    
    .article-content-right {
        max-width: 350px !important;    
    }
    
    .collapse {
        display: block !important;
    }

}
// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------


.d-flow-root {
    display: flow-root;
}

.pointer {
    cursor: pointer;
}

.transition {
    transition: 0.2s;
}

.no-style {
    list-style: none;
}

.bg {
    
    background-position: center;  
    background-repeat: no-repeat; 
    
    &-cover {
        background-size: cover;
    }

    &-contain {
        background-size: contain;
    }
}

.hyphens-auto {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.text-pre {
    white-space: pre-line;
}

.z-index-n {
    z-index: -1;   
}


@for $i from 0 through 9 {

    .opacity-#{$i} {
        opacity: #{"0."}#{$i};
        
    }

}


.num-circle {
    
    $circle_size: 2.2em;
    
    min-width: $circle_size;
    width: $circle_size;
    height: $circle_size;
    box-sizing: initial;
    text-align: center;
    border-radius: 50%;    
    line-height: $circle_size;
    box-sizing: content-box;       
    
    &-sm {
        @extend .num-circle;
    }
    
    &-md {
        $circle_size: 2.5em;
        @extend .num-circle;
        min-width: $circle_size;
        width: $circle_size;
        height: $circle_size;  
        line-height: $circle_size;  
        font-size: 1.25rem !important;    
    }
    
    &-lg {
        $circle_size: 3em;
        @extend .num-circle;
        min-width: $circle_size;
        width: $circle_size;
        height: $circle_size;
        line-height: $circle_size;
        font-size: 2rem !important;
    }
}


@each $name, $size in $spacers {

    .z-index-#{$name} {
        z-index: #{$name};
    }
    
    @each $direction, $short in $directions {        
        .position-#{$short}-#{$name} {  
            #{$direction}: #{$size};           
        }           
    }
}


/* COLORS */

@each $name, $color in $colors {
    
    .#{$name} { 
        color: $color;
    }    
    
    .bg-#{$name} { 
        background-color: $color;
    }
    
}

@each $name, $background in $backgrounds {
    
    .bg-#{$name} { 
        background-color: $background;
    }
    
}

/* BORDERS */

@each $name, $color in $colors {
    .border-#{$name} {
        border-color: $color !important;
    }
}


/* W-XXX */

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }

  }
}
.btn-close {
    
    background: none !important;
    
    float: right;
    
    width: auto !important;
    height: auto !important;
    
}

.close {
    @extend .btn-close;
}
//Custom styles

//base styles
/*
$selectize-font-family: "arial" !default;
$selectize-font-smoothing: inherit !default;
$selectize-font-size: 13px !default;
$selectize-line-height: 18px !default;
*/

$selectize-color-text: #7e7e7e !default;
$selectize-color-border: transparent !default;
$selectize-color-highlight: rgba(125,168,208,0.2) !default;
$selectize-color-input: transparent !default;
$selectize-color-input-full: $selectize-color-input !default;
$selectize-color-disabled: #fafafa !default;
$selectize-color-item: #f2f2f2 !default;
$selectize-color-item-text: $selectize-color-text !default;
$selectize-color-item-border: #d0d0d0 !default;
$selectize-color-item-active: #e8e8e8 !default;
$selectize-color-item-active-text: $selectize-color-text !default;
$selectize-color-item-active-border: #cacaca !default;
$selectize-color-dropdown: #fff !default;
$selectize-color-dropdown-border: #ececec !default; //@selectize-color-border
$selectize-color-dropdown-border-top: #000 !default;
$selectize-color-dropdown-item-active: #f0f0f0 !default;
$selectize-color-dropdown-item-active-text: #7e7e7e !default;
$selectize-color-dropdown-item-create-text: rgba(red($selectize-color-text), green($selectize-color-text), blue($selectize-color-text), 0.5) !default;
$selectize-color-dropdown-item-create-active-text: $selectize-color-dropdown-item-active-text !default;
$selectize-color-optgroup: $selectize-color-dropdown !default;
$selectize-color-optgroup-text: $selectize-color-text !default;
$selectize-lighten-disabled-item: 30% !default;
$selectize-lighten-disabled-item-text: 30% !default;
$selectize-lighten-disabled-item-border: 30% !default;
$selectize-opacity-disabled: 0.5 !default;

$selectize-shadow-input: none !default;
$selectize-shadow-input-focus: none !default;
$selectize-border: 0 !default;
$selectize-dropdown-border: none !default;
$selectize-border-radius: none !default;

$selectize-width-item-border: 0 !default;
$selectize-max-height-dropdown: 200px !default;

$selectize-padding-x: 20px !default;
$selectize-padding-y: 18px !default;
$selectize-padding-item-x: 6px !default;
$selectize-padding-item-y: 2px !default;
$selectize-padding-dropdown-item-x: 0 !default;
$selectize-padding-dropdown-item-y: 5px !default;
$selectize-margin-item-x: 3px !default;
$selectize-margin-item-y: 3px !default;

$selectize-arrow-size: 5px !default;
$selectize-arrow-color: #808080 !default;
$selectize-arrow-offset: 15px !default;

$selectize-caret-margin: 0 2px 0 0 !default;
$selectize-caret-margin-rtl: 0 4px 0 -2px !default;


@mixin selectize-border-radius ($radii) {
	-webkit-border-radius: $radii;
	-moz-border-radius: $radii;
	border-radius: $radii;
}

@mixin selectize-unselectable () {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@mixin selectize-box-shadow ($shadow) {
	-webkit-box-shadow: $shadow;
	box-shadow: $shadow;
}

@mixin selectize-box-sizing ($type: border-box) {
	-webkit-box-sizing: $type;
	-moz-box-sizing: $type;
	box-sizing: $type;
}

@mixin selectize-vertical-gradient ($color-top, $color-bottom) {
	background-color: mix($color-top, $color-bottom, 60%);
	background-image: -moz-linear-gradient(top, $color-top, $color-bottom); // FF 3.6+
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from($color-top), to($color-bottom)); // Safari 4+, Chrome 2+
	background-image: -webkit-linear-gradient(top, $color-top, $color-bottom); // Safari 5.1+, Chrome 10+
	background-image: -o-linear-gradient(top, $color-top, $color-bottom); // Opera 11.10
	background-image: linear-gradient(to bottom, $color-top, $color-bottom); // Standard, IE10
	background-repeat: repeat-x;
	filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{argb($color-top)}', endColorstr='#{argb($color-bottom)}', GradientType=0)"; // IE9 and down
}

//@import "plugins/drag_drop";
.selectize-control.plugin-drag_drop {
	&.multi > .selectize-input > div.ui-sortable-placeholder {
		visibility: visible !important;
		background: #f2f2f2 !important;
		background: rgba(black, 0.06) !important;
		border: 0 none !important;
		@include selectize-box-shadow (inset 0 0 12px 4px white);
	}

	.ui-sortable-placeholder::after {
		content: '!';
		visibility: hidden;
	}

	.ui-sortable-helper {
		@include selectize-box-shadow (0 2px 5px rgba(black, .2));
	}
}
//@import "plugins/dropdown_header";

.selectize-dropdown-header {
	position: relative;
	padding: $selectize-padding-dropdown-item-y $selectize-padding-dropdown-item-x;
	border-bottom: 1px solid $selectize-color-border;
	background: mix($selectize-color-dropdown, $selectize-color-border, 85%);
	@include selectize-border-radius ($selectize-border-radius $selectize-border-radius 0 0);
}

.selectize-dropdown-header-close {
	position: absolute;
	right: $selectize-padding-dropdown-item-x;
	top: 50%;
	color: $selectize-color-text;
	opacity: 0.4;
	margin-top: -12px;
	line-height: 20px;
	font-size: 20px !important;
}

.selectize-dropdown-header-close:hover {
	color: darken($selectize-color-text, 25%);
}
//@import "plugins/optgroup_columns";

.selectize-dropdown.plugin-optgroup_columns {
	.optgroup {
		border-right: 1px solid #f2f2f2;
		border-top: 0 none;
		float: left;
		@include selectize-box-sizing (border-box);
	}

	.optgroup:last-child {
		border-right: 0 none;
	}

	.optgroup:before {
		display: none;
	}

	.optgroup-header {
		border-top: 0 none;
	}
}
//@import "plugins/remove_button";

.selectize-control.plugin-remove_button {
	[data-value] {
		position: relative;
		padding-right: 24px !important;
	}

	[data-value] .remove {
		z-index: 1; /* fixes ie bug (see #392) */
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 17px;
		text-align: center;
		font-weight: bold;
		font-size: 12px;
		color: inherit;
		text-decoration: none;
		vertical-align: middle;
		display: inline-block;
		padding: $selectize-padding-item-y 0 0 0;
		border-left: 1px solid $selectize-color-item-border;
		@include selectize-border-radius (0 2px 2px 0);
		@include selectize-box-sizing (border-box);
	}

	[data-value] .remove:hover {
		background: rgba(black, 0.05);
	}

	[data-value].active .remove {
		border-left-color: $selectize-color-item-active-border;
	}

	.disabled [data-value] .remove:hover {
		background: none;
	}

	.disabled [data-value] .remove {
		border-left-color: lighten(desaturate($selectize-color-item-border, 100%), $selectize-lighten-disabled-item-border);
	}
}

.selectize-control {
	position: relative;
	padding: 0;
}

.selectize-dropdown, .selectize-input, .selectize-input input {
/*
	color: $selectize-color-text;
	font-family: $selectize-font-family;
	font-size: $selectize-font-size;
	line-height: $selectize-line-height;
	-webkit-font-smoothing: $selectize-font-smoothing;
*/
}

.selectize-input, .selectize-control.single .selectize-input.input-active {
	background: $selectize-color-input;
	cursor: text;
	display: inline-block;
}

.selectize-input {
	border: $selectize-border;
	padding: $selectize-padding-y $selectize-padding-x;
	display: inline-block;
	width: 100%;
	overflow: hidden;
	position: relative;
	z-index: 1;
	@include selectize-box-sizing (border-box);
	@include selectize-box-shadow ($selectize-shadow-input);
	@include selectize-border-radius ($selectize-border-radius);

	.selectize-control.multi &.has-items {
		$padding-x: $selectize-padding-x;
		$padding-top: $selectize-padding-y - $selectize-padding-item-y - $selectize-width-item-border;
		$padding-bottom: $selectize-padding-y - $selectize-padding-item-y - $selectize-margin-item-y - $selectize-width-item-border;
		padding: $padding-top $padding-x $padding-bottom;
	}

	&.full {
		background-color: $selectize-color-input-full;
	}

	&.disabled, &.disabled * {
		cursor: default !important;
	}

	&.focus {
		@include selectize-box-shadow ($selectize-shadow-input-focus);
	}

	&.dropdown-active {
		@include selectize-border-radius ($selectize-border-radius $selectize-border-radius 0 0);
	}

	> * {
		vertical-align: baseline;
		display: -moz-inline-stack;
		display: inline-block;
		zoom: 1;
		*display: inline;
	}

	.selectize-control.multi & > div {
		cursor: pointer;
		margin: 0 $selectize-margin-item-x $selectize-margin-item-y 0;
		padding: $selectize-padding-item-y $selectize-padding-item-x;
		background: $selectize-color-item;
		color: $selectize-color-item-text;
		border: $selectize-width-item-border solid $selectize-color-item-border;

		&.active {
			background: $selectize-color-item-active;
			color: $selectize-color-item-active-text;
			border: $selectize-width-item-border solid $selectize-color-item-active-border;
		}
	}

	.selectize-control.multi &.disabled > div {
		&, &.active {
			color: lighten(desaturate($selectize-color-item-text, 100%), $selectize-lighten-disabled-item-text);
			background: lighten(desaturate($selectize-color-item, 100%), $selectize-lighten-disabled-item);
			border: $selectize-width-item-border solid lighten(desaturate($selectize-color-item-border, 100%), $selectize-lighten-disabled-item-border);
		}
	}

	> input {
		&::-ms-clear {
			display: none;
		}

		display: inline-block !important;
		padding: 0 !important;
		min-height: 0 !important;
		max-height: none !important;
		max-width: 100% !important;
		margin: $selectize-caret-margin !important;
		text-indent: 0 !important;
		border: 0 none !important;
		background: none !important;
		line-height: inherit !important;
		-webkit-user-select: auto !important;
		@include selectize-box-shadow (none !important);

		&:focus {
			outline: none !important;
		}
	}
	
	.item {
        text-overflow: ellipsis;
        width: 80%;
        overflow: hidden;
        white-space: nowrap;
	}
}

.selectize-input::after {
	content: ' ';
	display: block;
	clear: left;
}

.selectize-input.dropdown-active::before {
	content: ' ';
	display: block;
	position: absolute;
	background: $selectize-color-dropdown-border-top;
	height: 1px;
	bottom: 0;
	left: 0;
	right: 0;
}

.selectize-dropdown {
	position: absolute;
	z-index: 10;
	border: $selectize-dropdown-border;
	background: $selectize-color-dropdown;
	margin: -1px 0 0 0;
	border-top: 0 none;
	height: auto;
	
	@include selectize-box-sizing (border-box);
	@include selectize-box-shadow (0 1px 3px rgba(black, 0.1));
	@include selectize-border-radius (0 0 $selectize-border-radius $selectize-border-radius);

	[data-selectable] {
		cursor: pointer;
		overflow: hidden;

		.highlight {
			background: $selectize-color-highlight;
			@include selectize-border-radius (1px);
		}
	}

	[data-selectable], .optgroup-header {
		padding: $selectize-padding-dropdown-item-y $selectize-padding-dropdown-item-x;
	}

	.optgroup:first-child .optgroup-header {
		border-top: 0 none;
	}

	.optgroup-header {
		color: $selectize-color-optgroup-text;
		background: $selectize-color-optgroup;
		cursor: default;
	}

	.active {
		background-color: $selectize-color-dropdown-item-active;
		color: $selectize-color-dropdown-item-active-text;

		&.create {
			color: $selectize-color-dropdown-item-create-active-text;
		}
	}

	.create {
		color: $selectize-color-dropdown-item-create-text;
	}
}

.selectize-dropdown-content {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: $selectize-max-height-dropdown;
}

.selectize-input input[type=select-one] {
    display: none!important;
}

.selectize-control.single .selectize-input {
	&, input {
		cursor: pointer;
	}

	&.input-active, &.input-active input {
		cursor: text;
	}

	&:after {
		font-family: 'icomoon';
		position: relative;
		float: right;
		top: 5px;
		font-size: 22px;
		content: "\e917";
		display: inline-block;
		/* By using an em scale, the arrows will size with the font */
		width: 0.4em;
		height: 0.4em;
		transform: rotate(90deg);
		margin-right: 0.2em;
		margin-left: 10px;
	}

	&.dropdown-active:after {
		font-family: 'icomoon';
		position: relative;
		float: right;
		top: 5px;
		left: -20px;
		font-size: 22px;
		content: "\e917";
		display: inline-block;
		/* By using an em scale, the arrows will size with the font */
		width: 0.4em;
		height: 0.4em;
		transform: rotate(270deg);
		margin-right: 0.2em;
	}
}

.selectize-control.rtl {
	&.single .selectize-input:after {
		left: $selectize-arrow-offset;
		right: auto;
	}

	.selectize-input > input {
		margin: $selectize-caret-margin-rtl !important;
	}
}

.selectize-control .selectize-input.disabled {
	opacity: $selectize-opacity-disabled;
	background-color: $selectize-color-disabled;
}

// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

@import "bootstrap5.scss";

body {
    color: map-get($colors, "color-f1");
    scroll-behavior: smooth;
    min-height: 100vh;
}

*:focus {
    outline:none;
}

p {
    line-height: 1.3em;
}


text {
    font-family: $font-family-sans-serif;
}

.font-size-inherit {
	font-size: inherit !important;
}
$colors: (
    'color-z1': rgb(45, 105, 165), 
    'color-z4': rgba(45, 105, 165, 0.8), 
    'color-d5': rgb(85, 135, 185), 
    'color-d6': rgb(65, 110, 170), 
    'color-e1': rgb(45, 90, 145), 
    'color-e3': rgb(130, 165, 210), 
    'color-e2': rgb(155, 180, 210), 
    'color-f1': rgb(0, 40, 80), 
    'color-h1': rgb(245, 150, 0), 
    'color-l': rgb(0, 75, 145), 
    'green': rgb(145, 195, 85), 
    'red': rgb(170, 25, 60), 
    'yellow': rgb(255, 205, 50), 
    'orange': rgb(245, 150, 0), 
    'light': rgba(0, 40, 80, 0.5), 
    'white': rgb(255, 255, 255), 
    'black': rgb(0, 0, 0),
    'delete': rgb(192, 0, 0)
);

$backgrounds: (
    'color-f1-95': rgba(0, 40, 80, 0.95), 
    'color-f1-80': rgba(0, 40, 80, 0.80), 
    'color-f1-60': rgba(0, 40, 80, 0.60), 
    
    'white-90': rgba(255, 255, 255, 0.9), 
    'logo': rgb(0, 74, 151), 
    'dark-color': rgb(0, 27, 54), 
    'middle-color': rgba(160, 195, 235, 0.4), 
    'middle-color-full': rgb(160, 195, 235), 
    'normal-color': rgba(45, 105, 165, 0.8), 
    'light-grey': rgb(242, 244, 246), 
    'grey': rgba(1, 38, 80, 0.05)
);


// magnific-popup
$mfp-controls-border-color: transparent !default; // Border color of controls

$directions: (
  left: "l",
  right: "r",
  top: "t",
  bottom: "b"
) !default;

$rightMaxWidth: 325px;


$colors: (
    
    'color-e1': rgb(75, 135, 195), 
    'color-h1': rgb(255, 205, 50), 
    'color-f1': rgb(0, 40, 80),
    'color-f4': rgb(123, 157, 186), 
    
    
    'color-e3': rgb(184, 206, 230), 
    'color-e2': rgb(219, 231, 243), 

    'color-z1': rgb(0, 0, 0), 
    'color-z4': rgba(75, 135, 195, 0.8), 
    
    
    'color-d4': rgb(165, 204, 234), 
    'color-d5': rgb(85, 135, 185), 
    'color-d6': rgb(65, 110, 170),     
    
    'green': rgb(95, 163, 34), 
    'red': 	rgb(255, 102, 102), 
    'yellow': rgb(255, 210, 0), 
    'orange': rgb(245, 150, 0), 
    'light': rgb(215, 240, 255), 
    'white': rgb(255, 255, 255), 
    'black': rgb(0, 0, 0),
    'blue': rgb(79, 149, 201),
    'pink': rgb(252, 191, 206),
    'delete': rgb(192, 0, 0),
	'dark': rgb(0, 40, 80),
    
);

$backgrounds: (
    'color-f1-95': rgba(60, 90, 115, 0.95), 
    'color-f1-80': rgba(60, 90, 115, 0.80),
    'color-f1-60': rgba(0, 40, 80, 0.60), 
	'color-c5': rgb(197, 219, 243),

    'white-90': rgba(255, 255, 255, 0.9), 
    
    'logo': rgb(75, 135, 195), 

    'dark-color': rgb(60, 90, 150),
    'middle-color': rgb(51, 102, 152), 
    'middle-color-full': rgb(51, 102, 152), 
    'normal-color': rgb(75, 135, 195), 

    'light-grey': rgb(242, 242, 242), 
    'grey': rgb(236, 238, 239),
    'deadline': rgb(242,192,20),
    'deadline-75': rgba(242,192,20, 0.75),
    'deadline-50': rgba(242,192,20, 0.50),
    'pink': rgb(252, 191, 206),
    'delete': rgb(192, 0, 0)
);


// magnific-popup
$mfp-controls-border-color: transparent !default; // Border color of controls

$directions: (
  left: "l",
  right: "r",
  top: "t",
  bottom: "b"
) !default;

$rightMaxWidth: 325px;


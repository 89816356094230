// -----------------------------------------------------------------------------
// This file contains styles that are specific to the event registration page.
// -----------------------------------------------------------------------------

#publication_order_container {

    .controls-container {
    
        @extend .d-flex, .justify-content-between, .mt-5;
    
    }
    
    #items {
    
        @extend .d-table, .w-100;
        
        .item-header, .item-footer {
        
            @extend .d-table-row;
            
        }
        
        .item-header {
        
            .total {
            
                @extend .text-right;
            
            }
            
        }
        
        .item-footer {
        
            .amount, .total {
            
                @extend .text-right;
            
            }
        
        }
        
        .title, .price, .amount, .total, .total-cell {
        
            @extend .d-table-cell;
        
        }
        
        .item {
        
            @extend .d-table-row;
            
            .title, .price, .amount, .total {
        
                @extend .py-1;
                
            }
            
            .amount {
            
                @extend .pr-2;
            
            }
            
            .total {
            
                @extend .text-right;
            
            }
            
            
        }
        
    
    }
    
    #steps {
        
        @extend .col-12, .px-4, .py-4, .border-bottom !optional;
    
    }
    
    #messages {
        
        @extend .col-12;
    
    }
    
    .error {
    
        input {
            border: 1px solid $danger !important;
        }
    
        .error-msg {
            color: $danger;
        }
    
    }
    
    .order-inner {
    
        @extend .col-12, .px-4, .py-4;
    
    }
    
    #publication_order_items {
    
        @extend .bg-grey;
        
    
    }

    #publication_order_address {
    
        @extend .bg-grey;
    
    }
    
    #publication_order_overview {
    
        @extend .bg-grey;
    
    }
    
    #publication_order_success {
    
        @extend .bg-grey;
    
    }
    
    #publication_order_failure {
    
        @extend .bg-grey;
    
    }
    
    ul {
    
        @extend .list-unstyled, .black, .mb-0, .px-0;
        
        li {
        
            @extend .mx-0, .mb-4, .align-items-center;
            
            label {
            
                @extend .d-table;
                
                input {
                
                    @extend .mr-3;
                
                }
                
                > span {
                
                    @extend .d-table-cell;
                
                }
            
            }
            
            &.error {
            
                label {
                
                    color: $danger;
                
                }
            
            }
        
        }
    
    }

}
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.55 !default;
$h4-font-size:                $font-size-base * 1.4 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

$grid-breakpoints: (
    xs: 0,
    sm: 360px,
    md: 768px,
    lg: 1024px,
    xl: 1440px
);

$container-max-widths: (
    sm: 360px,
    md: 768px,
    lg: 1024px,
    xl: 1440px
);

/* fonts */
$font-family-sans-serif: "Neue Haas Unica W01 Regular", sans-serif;
$font-weight-normal: 400 !default;
$font-weight-bold: $font-weight-normal;
$font-weight-bolder: $font-weight-normal;

/* tooltip */
$tooltip-bg: map-get($backgrounds, 'normal-color');

/* links */
$link-color: map-get($colors, 'color-f1') !default;
$link-decoration: none !default;
$link-hover-decoration: none !default;

/* forms */
$input-border-width: 0;

$input-padding-y: 1rem;
$input-padding-x: 1rem;


$input-color: map-get($colors, 'color-f1');
$input-placeholder-color: $input-color;
$input-plaintext-color: $input-color;

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;

$input-height: 60px;
$input-height-sm: 60px;
$input-height-lg: 60px;

$btn-border-radius: 0 !default;
$btn-border-radius-base: $btn-border-radius;
$btn-border-radius-large: $btn-border-radius;
$btn-border-radius-small: $btn-border-radius;


/* colors */
$primary: map-get($colors, 'color-f1') !default;
$blue: map-get($colors, 'color-f1') !default;
$secondary: map-get($colors, 'color-d6') !default;
$success: map-get($colors, 'green') !default;
$info: map-get($colors, 'green') !default;
$warning: map-get($colors, 'color-e3') !default;
$danger: map-get($colors, 'red') !default;
$light: map-get($colors, 'light') !default;
$dark:  map-get($colors, 'color-f1')  !default;



/* transition */
$transition-collapse: 1s ease !default;


// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

$enable-negative-margins: true;


/**
 * Basic typography style for copy text
 */


.font-light {
    font-family:'Neue Haas Unica W01 Light';
}

.regular,
.font-regular {
    font-family:'Neue Haas Unica W01 Regular';
}

.font-medium,
strong,
.strong {
    font-family:'Neue Haas Unica W01 Medium It';
}



$max-font-size: 50 !default;
$min-font-size: 10 !default;
$fonts-sizes: () !default;


@if length($fonts-sizes) == 0 {
  @for $size from $max-font-size through $min-font-size {
    $fonts-sizes: append($fonts-sizes, $size);
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);


    @each $size in $fonts-sizes {
      .line-height#{$infix}-#{$size} { line-height: #{$size}px !important; }
      .font-size#{$infix}-#{$size} { font-size: #{$size}px !important; }
    }
  }
}
// -----------------------------------------------------------------------------
// This file contains styles that are specific to the article page.
// -----------------------------------------------------------------------------

.main-content,
.article-content {

    &-text-cols {
        .textLink {
            text-decoration: underline;
        }

    }
    
    
    &-right {
        
        @extend .ml-md-5;
        @extend .float-md-right;
        
        @include respond-above('md') {
            width: $rightMaxWidth;
            min-width: $rightMaxWidth;
        } 
    }
    
    &-text {
    
        &-cols {
            column-count: auto;
            @extend .d-flow-root;
        }

    
    }
    
    
    .event-program {
        
        @extend .my-4;
        
        dl {
            @extend .my-2;
        }
        
        dt {
            @extend .strong;
            @extend .my-2;
        }  
              
        dd {
            @extend .row;
            @extend .m-0;
            
            .onepagerCol1 {
                @extend .col-2, .col-md-1, .p-md-0, strong;
            }
            .onepagerCol2 {
                @extend .col-10, .col-md-11, .strong;
            }
            .onepagerCol3 {
                @extend .col-10, .offset-2, .offset-md-1, .col-md-11;
                
                p {
                    @extend .strong;
                }
                
                .onepagerGrey {
                    @extend .font-regular;
                }
            }
            
        }
        

        
        
                
    }
    
          
}

// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------


footer {
    @extend .container;  
    
    
    * {
        @extend .color-e1;
    }
    
    .footer {
        
        
        @extend .py-4, .d-md-flex, .justify-content-between;
        
        border-top: 1px solid  map-get($colors, "color-e1") !important;
        
        &-navi {
            
            @extend .pl-0, .pl-lg-5, .mb-3; 
             
            margin: 0;
            
            li {
                @extend .d-block;
                @extend .d-lg-inline;
                @extend .mb-2;
                @extend .mb-md-0;
                margin-right: 40px;
                
            }
                
        }
                
        &-logo {
    
            max-height: 60px;
            
            &-text {
                @extend .pl-0, .pl-lg-5, .mb-3; 
                
                line-height: 1rem;
                margin-right: 30px;
            }
        }            
        
    }
    
}


// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

@mixin border ($style,  $sides...){

    @if ($style != "") {

        @if ($sides == "") {

            border: $style;

        } @else {

            @each $side in $sides {

               @if ($side == 'top' or
                    $side == 'right' or
                    $side == 'bottom' or
                    $side == 'left') {

                    border-#{$side}: $style;

                }

            }

        }

    }

}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}


@mixin fixed_header($break){
    
    $view: map-get($container-max-widths, $break);
    left: calc( ( ( 100vw - #{$view} - ( #{$spacer} * 0.95 ) ) / 2 )  );

}

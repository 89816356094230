

.toolbar {
    
    &-badge {
        @extend .bg-color-h1, .white;
        
        position: absolute;
        bottom: 15px;
        right: 10px;
        border-radius: 10px;
        height: 20px;
        width: 19px;
        display: block;
        text-align: center;
        line-height: 19px;
        font-size: 14px;
    }    
                    
    &-tab-pane {
        
        position: absolute;
        right: 0;
        top: 70px;
        z-index: 100;
    
        @include respond-below('lg') {
            top: 55px;
        }  

    
        .nav-tabs {
            
            .nav-link {
                border: 0;
                border-radius: 0;
                outline: 0;    
            }     
                
        }
                
        &-user {
            @extend .bg-white;
            @extend .p-5;
            
            right: 30px;
            height: auto;
            width: 400px;
            
            @include respond-below('lg') {
                right: 0;
            }              
                    
            @include respond-below('md') {
                width: 360px;
            }          
        }
                
        &-logged {
            
            height: auto;
            width: 1200px;     


            .nav-link.active, .nav-item.show .nav-link {
                @extend .text-primary;
                
                background-color: #fff !important;
                border-color: #fff !important;
            }                
            
            @include respond-below('lg') {
                right: 0;
                width: 100%;     
            }              
                    
            @include respond-below('md') {
                right: 0;
                width: 360px;
            } 
        }

    }
    
    &-nav-tabs {
        
        border: 0;
        
        .nav-item,
        .nav-link {
            border: 0;
            border-radius: 0;
            outline: 0;   
            position: relative; 
        }     
        
        .active:after {   
            width: 30px;
            content: " ";
            position: absolute;
            bottom: -30px;
            left: -1px;
            background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAPCAYAAAAYjcSfAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAHaADAAQAAAABAAAADwAAAADnk/CJAAABmklEQVQ4EWNkENVsZvj/v4aBXoCRsYWZ4dub/QxcIrxAO63oYG8vw5sblcxgi7692cXALSoKZJvRzGJGxqlACwtA5jPCLfn/n5FBTGs2MKiT4WLUYjAyzmV4dS2VgZHxP8hIJri5IIHs8DSgO5bAxajCAJoHMhdqIchIhE9hFoSGMjMcuLyC4T9DCEyIbJqRYQ2Dg24Ew+rVf5HNwLQUJGtszMrw8OtaIMsXWTGJ7M0M8tzBDGfP/kbXhwheZBmQQgHFUGA47EQWJpoN0gfSj8VCkBnYfQozXcaSk+HH+21ArgNMiAj6AAOHoBfDk+PfcanFbylIl7geN8O/X7uAcUw4HzMyHGNgYnNjeHnpKy4LQeLYgxdZB8gAfn5PoNBpZGEs7NNgdQQsBOkj7FOY6XK6ggzff+8H+lgfJgSnGRkuMnCyOjI8uvweLoaHQbylIEMkDEUZ/vw4wMDwXwthJuM1BhYOB4YX518jxPCzSLMUZJaotgQDw79DwJJLFZjhbwNjyI7h9dUX+K2hhqy0ngyDiMZeBhBNBgAAfUxlRi/SDzgAAAAASUVORK5CYII=);
            background-position: 0px;
            background-repeat: no-repeat;
            height: 15px;
            z-index: 101;
            
            @include respond-below('lg') {
                bottom: -24px;
            }  

            
        }     
    }
    
    &-modal-backdrop {
        z-index: 90;
    }
         
}



.profile-nav-tabs {
    
    .nav-item {
        @extend .px-5, .py-3, .font-size-16;
        
        @include respond-below('lg') {
            display: block;
        } 
        
    }
    
}


.header-tools {
    
   .toolbar-nav-tabs .nav-link.active, .toolbar-nav-tabs .nav-item.show .nav-link {
        @extend .white;
        background-color: transparent;
        border-color: transparent;
    }  
               
    .toolbar-nav-tabs .nav-link:hover, .toolbar-nav-tabs .nav-link:focus {
        border-color: transparent;
    }
}
